import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import Helper from "../Helper/Helper.js";
import { Pagination } from "antd";
import { CustomUseContext } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";
import { PageLoader } from "./shared/loader";
import { Nodata } from "./shared/nodata.js";
import Aos from "aos";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Bets({ commonApiCall, mybets, allbets, loading, currencyIcon }) {

  const { bettoggle, SetBetslipstate, } = CustomUseContext();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ once: true })
    document.title="HouseofPlinko | Bets";

    const data1 = {
      page: page,
      pageSize: 10,
    };

    const Data = {
      url: "user/getonegamehistory",
      method: "post",
      reducerName: "mybets",
      payload: data1,
    };
    commonApiCall(Data);
    const Data1 = {
      url: "user/getPlinkogamehistory",
      method: "post",
      reducerName: "allbets",
      payload: data1,
    };
    commonApiCall(Data1);
  }, [page]);

  const handlechange = (value) => {
    setPage(value);
  };

  const geticon = (cid) => {
    let getLogo = currencyIcon?.data?.filter(function (el) {
      if (el._id === cid) {
        return el;
      }
    });

    if (getLogo) return getLogo[0]?.image;
    else return null;
  };

  return (
    <>
      <PageLoader load={loading} />
      <div className="row pilnko_section_outer_wrap">
        <div className="col-xl-12 col-lg-12">
          <h3 className="page_title_wrap">Bets</h3>
            <p type="button" className="mx-4" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} /> Back
            </p>
          <div className="pilnko_tabs_outer_wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={{ active: activeTab === "1" }}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {t("All Bets")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={{ active: activeTab === "2" }}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {t("My bets")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="bets_table_outer_wrap ">
                  <div className="bets_table_inner_wrap table-responsive">
                    {allbets ? (
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="bet_id_col">{t("Bet ID")}</th>
                            <th>{t("Game")}</th>
                            <th>{t("Player")}</th>
                            <th>{t("Time")}</th>
                            <th className="bet_id_col">{t("Bet")}</th>
                            <th className="bet_payout_col">{t("Payout")}</th>
                            <th className="table_last_col">{t("Profit")}</th>
                          </tr>
                        </thead>

                        {allbets?.data[0]?.data?.length > 0 ? (
                          allbets?.data[0]?.data?.map((element, index) => (
                            <tbody key={index}>
                              <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                                <td>
                                  <h4>{element.bet_id || '---'}</h4>
                                </td>
                                <td>
                                  <h4>{element.game_name}</h4>
                                </td>
                                <td>
                                  <h4>{element.username}</h4>
                                </td>
                                <td>
                                  <h4>
                                    {Helper.formatDated(element.created_at)}
                                  </h4>
                                </td>
                                {element?.game_name == "Plinko" ? (
                                  <>
                                    <td className="bet_matic_amount">
                                      <h4 className="bet_white_color">
                                        {element?.bet_amount.toFixed(6)}
                                      </h4>
                                      <img src={geticon(element.cid)} alt="image" />
                                    </td>
                                    <td>
                                      <h4>{element.payout}x</h4>
                                    </td>
                                    <td className="table_last_col bet_matic_amount">
                                      {element?.profit_prediction ? (
                                        <h4 className="bet_green_color" data-tip data-for={element?._id} type="button">
                                          <ReactTooltip effect="solid" place="left" id={element?._id} type="success">
                                            <span className="text-dark">+ {element?.profit}</span>
                                          </ReactTooltip>
                                          + {element?.profit?.toFixed(6) || "---"}
                                        </h4>
                                      ) : (
                                        <h4 data-tip data-for={element?._id} type="button">
                                          <ReactTooltip effect="solid" place="left" id={element?._id} type="error">
                                            <span>- {element?.profit}</span>
                                          </ReactTooltip>
                                          <span className="bet_red_color">- {element?.profit?.toFixed(6) || "---"}</span>
                                        </h4>
                                      )}
                                      <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <h4 className="bet_white_color">---</h4>
                                    </td>
                                    <td>
                                      <h4 className="bet_white_color">---</h4>
                                    </td>
                                    <td className="table_last_col bet_matic_amount">
                                      <h4 className="bet_white_color text-center">---</h4>
                                    </td>
                                  </>
                                )
                                }

                              </tr>

                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <Nodata />
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    ) : null}
                  </div>

                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="bets_table_outer_wrap ">
                  <div className="bets_table_inner_wrap table-responsive">
                    {mybets ? (
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="bet_id_col">{t("Bet ID")}</th>
                            <th>{t("Game")}</th>
                            <th>{t("Player")}</th>
                            <th>{t("Time")}</th>
                            <th className="bet_id_col">{t("Bet")}</th>
                            <th className="bet_payout_col">{t("Payout")}</th>
                            <th className="table_last_col">{t("Profit")}</th>
                          </tr>
                        </thead>
                        {currencyIcon && mybets?.data[0]?.data?.length > 0 ? (
                          mybets?.data[0]?.data?.map((element, index) => (
                            <tbody
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => { bettoggle(); SetBetslipstate(element); }}
                            >
                              <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                                <td>
                                  <h4>{element.bet_id || '---'}</h4>
                                </td>
                                <td>
                                  <h4>{element.game_name}</h4>
                                </td>
                                <td>
                                  <h4>{element.username}</h4>
                                </td>
                                <td>
                                  <h4>
                                    {Helper.formatDated(element.created_at)}
                                  </h4>
                                </td>
                                {element?.game_name == "Plinko" ? (
                                  <>
                                    <td className="bet_matic_amount">
                                      <h4 className="bet_white_color">
                                        {element?.bet_amount.toFixed(6)}
                                      </h4>
                                      <img src={geticon(element.cid)} alt="image" />
                                    </td>
                                    <td>
                                      <h4>{element.payout}x</h4>
                                    </td>
                                    <td className="table_last_col bet_matic_amount">
                                      {element?.profit_prediction ? (
                                        <h4 className="bet_green_color" data-tip data-for={element?._id} type="button">
                                          <ReactTooltip effect="solid" place="left" id={element?._id} type="success">
                                            <span className="text-dark">+ {element?.profit}</span>
                                          </ReactTooltip>
                                          + {element?.profit?.toFixed(6) || "---"}
                                        </h4>
                                      ) : (
                                        <h4 data-tip data-for={element?._id} type="button">
                                          <ReactTooltip effect="solid" place="left" id={element?._id} type="error">
                                            <span>- {element?.profit}</span>
                                          </ReactTooltip>
                                          <span className="bet_red_color">- {element?.profit?.toFixed(6) || "---"}</span>
                                        </h4>
                                      )}
                                      <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <h4 className="bet_white_color">---</h4>
                                    </td>
                                    <td>
                                      <h4 className="bet_white_color">---</h4>
                                    </td>
                                    <td className="table_last_col bet_matic_amount">
                                      <h4 className="bet_white_color text-center">---</h4>
                                    </td>
                                  </>
                                )}
                              </tr>

                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <Nodata />
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    ) : null}
                  </div>
                  <div className="table_pagination_outer_wrap">
                    <div className="table_pagination_inner_wrap">
                      <Pagination
                        showSizeChanger={false}
                        defaultCurrent={page}
                        total={mybets?.data[0]?.pagination?.total}
                        onChange={handlechange}
                        current={page}
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  mybets: state.mybets,
  allbets: state.allbets,
  currencyIcon: state.currencyIcon,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bets);
