import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import hplogo from "../assets/images/hp-logo.png";
import hptele from "../assets/images/hptele.png";
import hpfacebook from "../assets/images/hpfacebook.png";
import hptwitter from "../assets/images/hptwitter.png";
import hpshare from "../assets/images/hpshare.png";
import hpgit from "../assets/images/hpgit.png";
import Rggames from "../assets/images/rg-games.png";
import eighteen from "../assets/images/eighteen.png";
import { useTranslation } from "react-i18next";
import Helper from "../Helper/Helper";

const backendAPI = Helper.backendAPI();

const Footer = ({ recommendRes, commonApiCall, getSocialMedia }) => {

  const { t } = useTranslation();
  const [showContent, setShowContent] = useState();
  const [Partners, setPartners] = useState([]);

  useEffect(() => {
    const Data = {
      url: "getSettings",
      method: "get",
      reducerName: "getSocialMedia",
    };
    commonApiCall(Data);
  }, []);

  useEffect(() => {
    const Data1 = {
      url: "getcms",
      method: "get",
      reducerName: "getAboutus",
    };
    commonApiCall(Data1, (response) => {
      if (response) {
        setShowContent(response.data[0]?.content);
      }
    });

    Helper.PostData(backendAPI + "user/get_partners").then((result) => {
      if (result?.status) {
        setPartners(result?.data);
      }
    })
  }, []);

  return (

    <div className="row no-gutters indFtR">
      <footer className="footer_bg_wrap">
        <div className="row no-gutters indFtR footer_wrap">
          <div className="col-md-3">
            <div className="footer_info_wrap">
              <div className="footer_logo_wrap">
                <img src={hplogo} alt="logo" width={100} />
                {" "}
              </div>

              <p dangerouslySetInnerHTML={{ __html: showContent }}></p>

              <div className="SocInDv">
                {getSocialMedia?.data?.Telegram && <a href={getSocialMedia?.data?.Telegram} target="_blank">
                  <img src={hptele} />
                </a>}
                {getSocialMedia?.data?.GitHub && <a href={getSocialMedia?.data?.GitHub} target="_blank">
                  <img src={hpgit} />
                </a>}
                {getSocialMedia?.data?.Twitter && <a href={getSocialMedia?.data?.Twitter} target="_blank">
                  <img src={hptwitter} />
                </a>}
                {getSocialMedia?.data?.Facebook && <a href={getSocialMedia?.data?.Facebook} target="_blank">
                  <img src={hpfacebook} />
                </a>}
                {getSocialMedia?.data?.Reddit && <a href={getSocialMedia?.data?.Reddit} target="_blank">
                  <img src={hpshare} />
                </a>}
                <a href="/">
                  <img src={Rggames} width={30} />
                </a>
                <a href="/">
                  <img src={eighteen} width={30} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer_link_common_wrap mb-2">
              <h4>Games</h4>
              <ul>
                {recommendRes?.data?.map((list, index) => (
                  <li key={index}>
                    <Link to={`/plinko?${list._id}`}>
                      {list?.game_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer_link_common_wrap mb-2">
              <h4>Link</h4>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/CRSw7lHTO0PYY">{t("Terms Of Service")}</Link>
                </li>
                <li>
                  <Link to="/CRWiFeR2zzlqA">{t("Privacy Policy")}</Link>
                </li>
                <li>
                  <Link to="/CRFw2rPDaR2KQ">Help center</Link>
                </li>
                <li>
                  <Link to="/CRo2PawQXl6Ek">FAQ's</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            {Partners?.length > 0 ?
              <div className="footer_link_common_wrap mb-2">
                <h4>Partners Link</h4>
                <ul>
                  {Partners?.map((list, index) => (
                    <li key={index}>
                      <a href={list.link} target="_blank">
                        {list.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              :
              null
            }
          </div>
        </div>
        <div className="container footer_bottom_bar">
          <div className="ftIr">
            <div className="ftCp">
              {getSocialMedia?.data?.Copyrightstext}
            </div>
          </div>
        </div>
      </footer>
    </div>

  );
};
const mapStateToProps = (state) => ({
  recommendRes: state.recommendRes,
  getSocialMedia: state.getSocialMedia,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
