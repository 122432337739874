import { configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc, bscTestnet, goerli, mainnet } from 'viem/chains';


export const { chains, publicClient, webSocketPublicClient } = configureChains(
    [goerli, bscTestnet],
    [publicProvider()],
)

export const metamask = new MetaMaskConnector({
    chains: [goerli, bscTestnet],
    options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true
    }
})

export const walletConnect = new WalletConnectConnector({
    chains: [goerli, bscTestnet],
    options: {
        projectId: "798c61d4067e16cf56ef3d51150c83d8",
        showQrModal: true,
    },
})

export const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [metamask, walletConnect]
})