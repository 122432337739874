import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import arrowchevronleft from "../assets/images/icons/arrow-chevron-left.svg";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { SocketContext } from "../context/socket";
import useGeolocation from "react-hook-geolocation";
import Helper from "../Helper/Helper";



const SignUp = ({
  setSignupmodal,
  setLoginmodal,
  commonApiCall,
  signUpResponse,
  loading,
}) => {

  const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ mode: "onBlur", });
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const geolocation = useGeolocation();

  const [LocationObj, setLocationObj] = useState({});
  const [AddressLocation, setAddressLocation] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);

  useEffect(() => {

    if (typeof signUpResponse != "undefined") {
      socket.emit("regUserMail", { _id: signUpResponse.data, "type": "website" });
    }

    if (geolocation) {
      setLocationObj(geolocation);
      getGeoLocationMethod(geolocation?.latitude, geolocation.longitude);
    }
  }, [geolocation, signUpResponse]);

  const getGeoLocationMethod = async (latitude, longitude) => {
    try {
      await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Helper?.googleAPIKey()}`)
        .then(response => response.json())
        .then(data => {
          setAddressLocation(data?.results[0]?.formatted_address);
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  const sigin_pop = () => {
    setLoginmodal(true);
    setSignupmodal(false);
  };

  const togglePasswordVisiblity = (val) => {
    if (val === "oldpwd") {
      setPasswordShown(passwordShown ? false : true);
    } else if (val === "newpwd") {
      setPasswordShown1(passwordShown1 ? false : true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const onSubmit = (data) => {
    data['type'] = "website";
    data['location'] = AddressLocation;

    const Data = {
      url: "user/user_registration",
      method: "post",
      reducerName: "signUpResponse",
      component: "SignUp",
      payload: data,
    };
    commonApiCall(Data);
    reset();
  };

  return (
    <>
      <form
        className="sigin_form_wrap"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={"off"}
      >
        <div className="form-group">
          <label htmlFor="username">{t("User Name")}</label>
          <input
            type="text"
            placeholder="Enter Your User Name"
            id="username"
            name="username"
            onKeyDown={handleKeyDown}
            {...register("username", {
              required: t("userreq"),
              minLength: {
                value: 3,
                message: t("minlen"),
              },
              pattern: {
                value: /^([a-zA-Z0-9 _-]+)$/,
                message: "Alphanumeric characters only allowed",
              },
            })}
          />
          {errors.username && (
            <p className="text-danger">{errors.username.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">{t("Email Address")}</label>
          <input
            type="text"
            placeholder="Enter Your Email"
            id="email_address"
            name="email_address"
            onKeyDown={handleKeyDown}
            {...register("email_address", {
              required: t("emailreq"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("emailpat"),
              },
            })}
          />
          {errors.email_address && (
            <p className="text-danger">{errors.email_address.message}</p>
          )}
        </div>
        <div className="form-group">
          <div className="password_outer_wrap">
            <label htmlFor="password">{t("Password")}</label>
          </div>

          <div className="forgot_input_outer_wrap">
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Enter Your Password"
              id="password"
              name="password"
              {...register("password", {
                required: t("passreq"),
                minLength: {
                  value: 8,
                  message: t("newpassmin"),
                },
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})*/,
                  message: t("newpasspattern"),
                },
              })}
              onKeyDown={handleKeyDown}
            />

            <div className="show-button">
              {passwordShown ? (
                <FontAwesomeIcon
                  className="eyeIcon"
                  style={{ cursor: "pointer" }}
                  onClick={() => togglePasswordVisiblity("oldpwd")}
                  icon={faEye}
                />
              ) : (
                <FontAwesomeIcon
                  className="eyeIcon"
                  icon={faEyeSlash}
                  style={{ cursor: "pointer" }}
                  onClick={() => togglePasswordVisiblity("oldpwd")}
                />
              )}
            </div>

            {errors.password && (
              <p className="text-danger">{errors.password.message}</p>
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="con_password">{t("Confirm Password")}</label>
          <input
            type={passwordShown1 ? "text" : "password"}
            placeholder="Enter Your Confirm Password"
            id="confirm_password"
            name="confirm_password"
            {...register("confirm_password", {
              validate: (value) => value === getValues("password") || t("passmatch"),
              required: t("confirmpass"),
            })}
            onKeyDown={handleKeyDown}
          />

          <div className="show-button">
            {passwordShown1 ? (
              <FontAwesomeIcon
                className="eyeIcon"
                style={{ cursor: "pointer" }}
                onClick={() => togglePasswordVisiblity("newpwd")}
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                className="eyeIcon"
                icon={faEyeSlash}
                style={{ cursor: "pointer" }}
                onClick={() => togglePasswordVisiblity("newpwd")}
              />
            )}
          </div>
          {errors.confirm_password && (
            <p className="text-danger">{errors.confirm_password.message}</p>
          )}
        </div>

        <div className="form-group">
          <label className="d-flex" type="button">
            <input
              type="checkbox"
              name="check_box"
              className="mt-1 mx-2"
              {...register("check_box", {
                required: "You must accept the terms and conditions",
              })}
            />{" "}
            <p className="m-0">{t("aggrement")}</p>
          </label>
          {errors.check_box && (
            <p className="text-danger">{errors.check_box.message}</p>
          )}
        </div>

        <div className="signin_submit">
          <button type="submit" className="signin_btn_wrap" disabled={loading}>
            {loading ? "Loading ..." : "Sign up"}
          </button>
          <button type="button" className="signup_btn_wrap" onClick={sigin_pop}>
            Sign in
            <img src={arrowchevronleft} alt="arrow" />
          </button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  signUpResponse: state.signUpResponse,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
