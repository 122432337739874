import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import Forgetpassword from "./Forgetpassword";
import { useTranslation } from "react-i18next";
import arrowchevronleft from "../assets/images/icons/arrow-chevron-left.svg";
import { connect } from "react-redux";
import logo from '../assets/images/hp-logo.png';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

const Signin = ({
  loginmodal,
  setSignupmodal,
  setLoginmodal,
  commonApiCall,
  loading,
  ForgotpwdRes,
  clearstate,
}) => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur", });
  const { t } = useTranslation();

  useEffect(() => {
    if (ForgotpwdRes) {
      if (ForgotpwdRes?.status) {
        forgottoggle();
        clearstate({
          reducerName: "ForgotpwdRes",
        });
      }
    }
  }, [ForgotpwdRes]);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  // Forgot Model
  const [forgotmodal, setForgotmodal] = useState(false);
  const forgottoggle = () => {
    setForgotmodal(!forgotmodal);
  };

  const forgotClose = () => {
    setForgotmodal(false);
  };

  const signup_pop = () => {
    setSignupmodal(true);
    setLoginmodal(false);
  };

  const togglePasswordVisiblity = (val) => {
    if (val === "oldpwd") {
      setPasswordShown(passwordShown ? false : true);
    }
  };


  const onSubmit = (data) => {
    let PostData = {
      email: data.email.toLowerCase(),
      password: data.password,
    }
    const Data = {
      url: "user/user_login",
      method: "post",
      reducerName: "signInResponse",
      component: "Signin",
      payload: PostData,
    };
    commonApiCall(Data);
    reset();
  };

  return (
    <>
      <div>
        <form className="sigin_form_wrap" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="form-group">
            <label htmlFor="email">{t("Email Address")}</label>
            <input
              type="text"
              placeholder="Enter Your Email"
              id="email"
              name="email"
              onKeyDown={handleKeyDown}
              {...register("email", {
                required: t("emailreq"),
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("emailpat"),
                },
              })}
            />
            {errors.email && (
              <p className="text-danger">{errors.email.message}</p>
            )}
          </div>
          <div className="form-group">
            <div className="password_outer_wrap">
              <label htmlFor="password">{t("Password")}</label>
              <h6 onClick={forgottoggle}>{t("Forgot Password")}</h6>
            </div>
            <div className="forgot_input_outer_wrap">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Enter Your Password"
                id="password"
                name="password"
                onKeyDown={handleKeyDown}
                {...register("password", {
                  required: t("passreq"),
                })}
              />
              <div className="show-button">
                {passwordShown ? (
                  <FontAwesomeIcon
                    className="eyeIcon"
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("oldpwd")}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="eyeIcon"
                    icon={faEyeSlash}
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("oldpwd")}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
            </div>
          </div>
          <div className="signin_submit">
            <button
              type="submit"
              className="signin_btn_wrap"
              disabled={loading}
            >
              {loading ? "Loading..." : "Sign in"}
            </button>
            <button
              type="button"
              className="signup_btn_wrap"
              onClick={signup_pop}
            >
              Sign up
              <img src={arrowchevronleft} alt="arrow" />
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={forgotmodal}
        toggle={forgottoggle}
        centered={true}
        className="signin_model_wrap forgot_model_wrap model_common_style_wrap"
      >
        <ModalBody>
          <div className="close_model" onClick={forgotClose}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={logo} alt="popupbg" width={100} />
          </div>
          <Forgetpassword
            loginmodal={loginmodal}
            setLoginmodal={setLoginmodal}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  signInResponse: state.signInResponse,
  loading: state.loading,
  ForgotpwdRes: state.ForgotpwdRes,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
