import React, { useContext, createContext, useState } from "react";

const CustomContext = createContext();

export const CustomUseContext = () => useContext(CustomContext);

export const ContextProvider = ({ children }) => {

  const [loaderMessage, setloaderMessage] = useState(null);
  const [headerSlider, setHeaderSlider] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [updatedBalance, setUpdatedBalance] = useState("");
  const [ballsFalling, setBallsFalling] = useState(0);
  const [gameId, setGameId] = useState("");
  const [globalList, setGlobalList] = useState([]);
  const [WalletBalanceData, setWalletBalance] = useState([]);
  const [Decimal, setDecimal] = useState(2);
  const [HomeDecimal, setHomeDecimal] = useState(8);
  const [routeurl, setRouteurl] = useState();
  const [sitesettings, setSitesettings] = useState();

  const [sidebar, setSidebar] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [signupmodal, setSignupmodal] = useState(false);
  const [gamemodal, setGamemodal] = useState(false);

  const [activeGames, setActiveGames] = useState([]);
  const [currentGame, setCurrentGame] = useState();
  const [sideitem, setSideitem] = useState();
  const [amounValueInfo, setAmounValueInfo] = useState(1);
  const [searchText, setSearchText] = useState();

  const [betmodal, setBetmodal] = useState(false);
  const bettoggle = () => setBetmodal(!betmodal);
  const [betslipstate, SetBetslipstate] = useState();
  const [profiledata, setProfiledata] = useState();


  //  03/10/2023

  const [userToken, setUserToken] = useState("");

  return (
    <CustomContext.Provider
      value={{
        loaderMessage, setloaderMessage,
        WalletBalanceData,
        sitesettings, setSitesettings,
        otpModal, setotpModal,
        setWalletBalance,
        headerSlider,
        updatedBalance,
        setSelectedCurrency,
        selectedCurrency,
        setUpdatedBalance,
        setHeaderSlider,
        ballsFalling,
        setBallsFalling,
        setGameId,
        gameId,
        globalList,
        setGlobalList,
        setSidebar,
        sidebar,
        setSignupmodal,
        signupmodal,
        loginmodal,
        setLoginmodal,
        gamemodal, setGamemodal,
        Decimal,
        setDecimal,
        activeGames,
        setActiveGames,
        sideitem,
        setSideitem,
        setCurrentGame,
        currentGame,
        amounValueInfo,
        setAmounValueInfo,
        setSearchText,
        searchText,
        setHomeDecimal,
        HomeDecimal,
        betmodal,
        setBetmodal,
        bettoggle,
        betslipstate,
        SetBetslipstate,
        routeurl,
        setRouteurl,
        profiledata,
        setProfiledata,


        userToken, setUserToken,
      }}
    >
      {children}
    </CustomContext.Provider>
  );
};
