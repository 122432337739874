import React from 'react';

import logo from '../assets/images/hpgamelogo.png';
import hpbannerballleft from "../assets/images/hpbannerballleft.png";
import hpbannerballleft1 from "../assets/images/hpbannerballleft1.png";
import hpbannerballright from "../assets/images/hpbannerballright.png";
import hpbannerballright1 from "../assets/images/hpbannerballright1.png";
import hpcoinleft from "../assets/images/hpcoinleft.png";
import hpcoinright from "../assets/images/hpcoinright.png";
import hpcounter from "../assets/images/hpcounter.png";
import { CustomUseContext } from "../context/ContextProvider";
import { BounceInDownButton } from '../animate/bouncein';

export const HomeBanner = (props) => {


    const { setSignupmodal, signupmodal, userToken, setUserToken } = CustomUseContext();

    const signup = () => setSignupmodal(!signupmodal);

    return (
        <div className="HpbannerDv">
            <div className='head'>
                <h1>{props?.heading}</h1>
            </div>
            {userToken ? null :
                <button className="btn btnPrp btn-11438 d-block mx-auto"
                    style={{ position: 'relative', zIndex: '10' }}
                    onClick={signup}
                >Register</button>
            }
            <BounceInDownButton>
                <img src={logo} className="lgtAnmIG" width={337} height={191} />
            </BounceInDownButton>
            <img src={hpcounter} className="img-fluid d-block mx-auto hpcounter" />
            <div className="HpBannerDvLtPosIg">
                <img src={hpbannerballleft} className="img-fluid hpbannerballleft" />
                <img src={hpbannerballleft1} className="img-fluid hpbannerballleft1" />
                <img src={hpcoinleft} className="img-fluid hpcoinleft" />
            </div>
            <div className="HpBannerDvRtPosIg">
                <img src={hpbannerballright} className="img-fluid hpbannerballright" />
                <img src={hpbannerballright1} className="img-fluid hpbannerballright1" />
                <img src={hpcoinright} className="img-fluid hpcoinright" />
            </div>
        </div>
    )
}