
import ethjson from '../contract/eth.json'
import bnbjson from '../contract/bnb.json'
import tokenjson from '../contract/token.json'

import Web3 from "web3";
import { toast } from "react-toastify";


export async function allowancefn(tokenaddress, address) {

    const web3 = new Web3(window.ethereum);
    let spenderContract;

    if (window.ethereum.networkVersion === "5") {
        spenderContract = ethjson.contractAddress;
    } else if (window.ethereum.networkVersion === "97") {
        spenderContract = bnbjson.contractAddress;
    } else {
        toast.error('Network error');
        return null
    }

    let tokenContract = new web3.eth.Contract(tokenjson.abi, tokenaddress)

    return new Promise(async (resolve, reject) => {
        try {
            var allowanceVal = await tokenContract.methods.allowance(address, spenderContract).call()
            resolve(String(allowanceVal))
        }
        catch (err) {
            console.error(err);
            reject(
                toast.error('Something went wrong failed')
            )
        }
    });
}