import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

const OTPVerify = ({ commonApiCall, loading, logotpRes}) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur", });
    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (logotpRes) {
            console.log(logotpRes);
        }
    }, [logotpRes])

    const onSubmit = (data) => {
        const verify = {
            _id: localStorage.getItem("userID"),
            otp: data.tfa_code,
        };
        const Data = {
            url: "user/log_otp",
            method: "post",
            reducerName: "logotpRes",
            payload: verify,
        };
        commonApiCall(Data);
    };

    return (
        <>
            <div>
                <form className="sigin_form_wrap" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="email">Enter OTP</label>
                        <input
                            type="text"
                            placeholder="Enter Your otp"
                            id="email"
                            name="tfa_code"
                            onKeyDown={handleKeyDown}
                            {...register("tfa_code", {
                                required: "OTP is required",
                                pattern: {
                                    value: /^[0-9]{1,4}$/,
                                    message: "Invalid OTP"
                                }
                            })}
                        />
                        {errors.tfa_code && (
                            <p className="text-danger">{errors.tfa_code.message}</p>
                        )}
                    </div>

                    <div className="signin_submit">
                        <button
                            type="submit"
                            className="signin_btn_wrap"
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Verify"}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    signInResponse: state.signInResponse,
    loading: state.loading,
    logotpRes:state.logotpRes,
});

const mapDispatchToProps = (dispatch) => ({
    commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
    clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerify);
