import React from "react"
import NodataFile from './nodata.json';

import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

export const Nodata = () => {

    const { t } = useTranslation();

    return (
        <div className="no_data">
            <div className="text-center">
                <Lottie animationData={NodataFile} loop={false} style={{ width: "250px" }} />
                <p>{t("Records Not Found")}</p>
            </div>
        </div>
    )
}