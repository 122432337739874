import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import helpers from "../Helper/Helper";
import { CustomUseContext } from "../context/ContextProvider";
import CryptoJS from "crypto-js"

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import hpplinko from "../assets/images/Plingo FAVicon.png";
import Aos from "aos";
import { HomeBanner } from "./Homebanner";
import { Nodata } from "./shared/nodata";
import { PageLoader } from "./shared/loader";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { BounceInDownButton } from "../animate/bouncein";
import useGeolocation from "react-hook-geolocation";
import Helper from "../Helper/Helper";

function Home({
  commonApiCall,
  loading,
  currencyIcon,
  allbets,
  highbets,
  mybets,
  homecontentRes
}) {

  const navigate = useNavigate();

  const { sideitem, userToken, setProfiledata, setSignupmodal } = CustomUseContext();
  const [AddressLocation, setAddressLocation] = useState(null);
  const [homeContent, sethomeContent] = useState({});
  const geolocation = useGeolocation();

  //Tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => { if (activeTab !== tab) setActiveTab(tab) };
  const signuptoggle = () => {
    setSignupmodal(true);
  };

  // Initial call
  useEffect(() => {

    document.title="HouseofPlinko | Home";
    if (homecontentRes?.status) {
      sethomeContent(homecontentRes?.data[0])
    }
  }, [homecontentRes])

  useEffect(() => {
    window.scrollTo(0, 0);
    const data1 = {
      page: 1,
      pageSize: 10,
    };

    const Data1 = {
      url: "user/getPlinkogamehistory",
      method: "post",
      reducerName: "allbets",
      payload: data1,
    };
    commonApiCall(Data1);

    const Data2 = {
      url: "user/get_highest_win",
      method: "get",
      reducerName: "highbets",
    };
    commonApiCall(Data2);

    const homeData = {
      url: "user/get_homecontent",
      method: "get",
      reducerName: "homecontentRes",
    };
    commonApiCall(homeData);

    const Data3 = {
      url: "user/getonegamehistory",
      method: "post",
      reducerName: "mybets",
      payload: data1,
    };

    if (userToken) {
      commonApiCall(Data3);
    }
  }, []);

  useEffect(() => {
    if (sideitem == "2") {
      setActiveTab("2");
    } else if (sideitem == "3") {
      setActiveTab("3");
    } else if (sideitem == "4") {
      setActiveTab("4");
    }
  }, [sideitem]);

  let currentUrlParams = new URLSearchParams(window.location.search);
  let Verify = currentUrlParams.get("activation_code");
  let linkVerify = currentUrlParams.get("link_mail");
  let withVerify = currentUrlParams.get("approve");


  useEffect(() => {
    if (Verify) {
      let data1 = {
        verified: Verify,
      };
      const Data = {
        url: "user/register_usercheck",
        method: "post",
        reducerName: "registercheck",
        payload: data1,
      };
      commonApiCall(Data, (response) => {
        const verifydata = {
          url: "user/verify_user/" + Verify,
          method: "post",
          reducerName: "verify_user",
          component: "home",
        };
        if (response) {
          if (response.status) {
            commonApiCall(verifydata, (response) => {
              if (response) {
                navigate("/");
              }
            });
          } else {
            navigate("/");
          }
        }
      });
    }

    const linkData = {
      url: "user/verify_linkmail/" + linkVerify,
      method: "post",
      reducerName: "linkMailRes",
      component: "home",
    };

    if (linkVerify) {
      commonApiCall(linkData, (response) => {
        if (response) {
          if (response.status) {
            navigate("/");
          }
        }
      });
    }
  }, [Verify, linkVerify]);

  useEffect(() => {
    const short = withVerify?.substring(0, 10);

    const withdrawrequest = {
      withdraw_verifcation_string: short,
    };
    const Data = {
      url: "user/withdraw_usercheck",
      method: "post",
      reducerName: "withdrawcheck",
      payload: withdrawrequest,
    };
    if (short) {
      commonApiCall(Data, (response) => {
        if (Object.keys(withVerify)?.length !== 0) {
          let data1 = {
            id: withVerify,
          };
          const Data = {
            url: "user/verify_withdraw",
            method: "post",
            reducerName: "verify_withdraw",
            payload: data1,
          };
          if (response) {
            if (response?.status) {
              commonApiCall(Data, (response) => {
                if (response) {
                  if (response?.status) {
                    navigate("/");
                  }
                }
              });
            } else {
              navigate("/");
            }
          }
        }
      });
    }
  }, []);

  let curr = currencyIcon && currencyIcon?.data[1]?._id;

  useEffect(() => {
    let top = {
      cid: curr,
    };
    const Data = {
      url: "user/gettodaywinners",
      method: "post",
      reducerName: "todayWinners",
      payload: top,
    };
    commonApiCall(Data);
  }, [curr]);

  useEffect(() => {
    if (userToken) {
      const Data = {
        url: "user/getUserdetails",
        method: "post",
        reducerName: "getprofile",
      };
      commonApiCall(Data, (response) => {
        if (response) {
          setProfiledata(response.data?.details);
        }
      });
    }
  }, [userToken]);

  useEffect(() => {

    Aos.init({ once: true });

    setTimeout(async () => {
      let obj = telegram.initData;
      telegram.expand();

      if (obj) {
        let botToken = helpers.getBottoken();
        let transformInitDataObj = await transformInitData(obj);
        let isCheckSignature = await checkWebAppSignature(
          botToken,
          transformInitDataObj
        );

        if (isCheckSignature) {
          let userInfo = JSON.parse(transformInitDataObj?.user);
          let postData = {
            telegramId: userInfo?.id,
            first_name: userInfo?.first_name,
            location: AddressLocation,
          };

          const Data = {
            url: "user/telegramLoginUser",
            method: "post",
            reducerName: "signInResponse",
            component: "Signin",
            payload: postData,
          };
          commonApiCall(Data);

        } else {
          toast.error("Invalid Request !!!");
        }
      }
    }, 1500);

  }, []);

  useEffect(() => {
    if (geolocation) {
      getGeoLocationMethod(geolocation?.latitude, geolocation?.longitude);
    }
  }, [geolocation])

  // Functionalities
  let telegram = window?.Telegram?.WebApp;

  const checkWebAppSignature = async (token, data) => {
    const data_chack_string = await Object.keys(data)
      .filter((key) => key !== "hash")
      .map((key) => `${key}=${data[key]}`)
      .sort()
      .join("\n");
    const secret = await CryptoJS.HmacSHA256(token, "WebAppData");
    const signature = await CryptoJS.HmacSHA256(data_chack_string, secret);
    const hex = await signature.toString(CryptoJS.enc.Hex);
    return data.hash === hex;
  };
  const transformInitData = async (initData) => {
    return await Object.fromEntries(new URLSearchParams(initData));
  };

  const getGeoLocationMethod = async (latitude, longitude) => {

    if (latitude && longitude) {
      try {
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Helper?.googleAPIKey()}`)
          .then(response => response.json())
          .then(data => {
            setAddressLocation(data?.results[0]?.formatted_address);
          });
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  const geticon = (cid) => {
    let getLogo = currencyIcon?.data?.filter(function (el) {
      if (el._id === cid) {
        return el;
      }
    });

    if (getLogo) return getLogo[0]?.image;
    else return null;
  };

  return (
    <Fragment>
      <PageLoader load={loading} />
      <div className="animated_div">
        <HomeBanner heading={homeContent?.heading} />

        <div className="PlyStpStsDv">
          <div className="container">
            <div className="row justify-content-center mx-0">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-md-4">
                    <div className="PlyStpStsDvInr p-3">
                      <h2>{homeContent?.titleOne}</h2>
                      <p>{homeContent?.contentOne}</p>
                      <div className="PlyStpStsDvInrStep">
                        <span>01</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="PlyStpStsDvInr p-3">
                      <h2>{homeContent?.titleTwo}</h2>
                      <p>{homeContent?.contentTwo}</p>
                      <div className="PlyStpStsDvInrStep">
                        <span>02</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="PlyStpStsDvInr p-3">
                      <h2>{homeContent?.titleThree}</h2>
                      <p>{homeContent?.contentThree}</p>
                      <div className="PlyStpStsDvInrStep">
                        <span>03</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="LtstBtRceDv">
          <div className="container">
            <div className="LtstBtRceDvInr">
              <h2>Latest Bet & Race</h2>
              <BounceInDownButton>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Latest Bets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      High Scorers
                    </NavLink>
                  </NavItem>

                  {userToken ? (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        My Bets
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
              </BounceInDownButton>
            </div>
            <TabContent activeTab={activeTab} className="mt-4">
              <TabPane tabId="1">
                <div className="LtstBtRceDvTbl">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Game</th>
                          <th>Player</th>
                          <th>Bet Amount</th>
                          <th>Multiplier</th>
                          <th>Profit Amount</th>
                        </tr>
                      </thead>

                      {allbets?.data[0]?.data?.length > 0 ? (
                        allbets?.data[0]?.data?.map((element, index) => (
                          <tbody key={index}>
                            <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                              <td>
                                <img src={hpplinko} className="me-2" />
                                Plinko
                              </td>
                              <td>{element.username || "---"}</td>
                              <td>
                                {element?.bet_amount || "---"}
                                <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                              </td>
                              <td>x{element.payout || "---"}</td>
                              <td>
                                {element?.profit_prediction ? (
                                  <span className="sucs" data-tip data-for={element?._id} type="button">
                                    <ReactTooltip id={element?._id} type="success">
                                      <span className="text-dark">+ {element?.profit}</span>
                                    </ReactTooltip>
                                    + {element?.profit?.toFixed(6) || "---"}
                                    <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                  </span>
                                ) : (
                                  <span className="flur" data-tip data-for={element?._id} type="button">
                                    <ReactTooltip id={element?._id} type="error">
                                      <span>- {element?.profit}</span>
                                    </ReactTooltip>
                                    - {element?.profit?.toFixed(6) || "---"}
                                    <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <Nodata />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="2">
                <div className="LtstBtRceDvTbl">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Game</th>
                          <th>Player</th>
                          <th>Bet Amount</th>
                          <th>Multiplier</th>
                          <th>Profit Amount</th>
                        </tr>
                      </thead>

                      {highbets?.data?.length > 0 ? (
                        highbets?.data?.map((element, index) => (
                          <tbody key={index}>
                            <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                              <td>
                                <img src={hpplinko} className="me-2" />
                                Plinko
                              </td>
                              <td>{element.user_id?.username || "---"}</td>
                              <td>
                                {element?.bet_amount || "---"}
                                <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                              </td>
                              <td>x{element.payout || "---"}</td>
                              <td>
                                <span className="sucs" data-tip data-for={element?._id} type="button">
                                  <ReactTooltip id={element?._id} type="success">
                                    <span className="text-dark">+ {element?.profit}</span>
                                  </ReactTooltip>
                                  + {element?.profit?.toFixed(3) || "---"}
                                  <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <Nodata />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="3">
                <div className="LtstBtRceDvTbl">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Game</th>
                          <th>Player</th>
                          <th>Bet Amount</th>
                          <th>Multiplier</th>
                          <th>Profit Amount</th>
                        </tr>
                      </thead>

                      {mybets?.data[0]?.data?.length > 0 ? (
                        mybets?.data[0]?.data?.map((element, index) => (
                          <tbody key={index}>
                            <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                              <td>
                                <img src={hpplinko} className="me-2" />
                                Plinko
                              </td>
                              <td>{element.username || "---"}</td>
                              <td>
                                {element?.bet_amount || "---"}
                                <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                              </td>
                              <td>x{element.payout || "---"}</td>
                              <td>
                                {element?.profit_prediction ? (
                                  <span className="sucs" data-tip data-for={element?._id} type="button">
                                    <ReactTooltip id={element?._id} type="success">
                                      <span className="text-dark">+ {element?.profit}</span>
                                    </ReactTooltip>
                                    + {element?.profit?.toFixed(6) || "---"}
                                    <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                  </span>
                                ) : (
                                  <span className="flur" data-tip data-for={element?._id} type="button">
                                    <ReactTooltip id={element?._id} type="error">
                                      <span>- {element?.profit}</span>
                                    </ReactTooltip>
                                    - {element?.profit?.toFixed(6) || "---"}
                                    <img src={geticon(element.cid)} className="mx-2" width={20} />{" "}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <Nodata />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>

        <div className="LtGtStrtdDv">
          <h2>Let's get Started</h2>
          <BounceInDownButton>
            {userToken ?
              <Link className="btn btnPrp btn-11438 d-block mx-auto"
                to="/plinko?game_id=633a9319a87884818c1b3ac8">
                Play Now
              </Link>
              :
              <button className="btn btnPrp btn-11438 d-block mx-auto"
                onClick={signuptoggle}>
                Sign Up Now
              </button>
            }
          </BounceInDownButton>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  currencyIcon: state.currencyIcon,
  allbets: state?.allbets,
  highbets: state?.highbets,
  homecontentRes: state?.homecontentRes,
  currencyIcon: state.currencyIcon,
  mybets: state.mybets,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
