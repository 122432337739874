import React, { useEffect, useState, useMemo } from "react";
import down_arrow from "../assets/images/down_arrow.png";
import { connect } from "react-redux";
import Helper from "../Helper/Helper.js";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { PageLoader } from "./shared/loader";
import { Nodata } from "./shared/nodata";
import Aos from "aos";
import ReactTooltip from "react-tooltip";

const Transaction = ({
  commonApiCall,
  transactiondata,
  loading,
  verify_withdraw,
}) => {
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  const [transaction, setTransaction] = useState([]);

  useEffect(() => {
    Aos.init({ once: true })
    document.title = "HouseofPlinko | Transactions";
    let detail = {
      page: page,
      pageSize: pageSize,
    };
    const Data = {
      url: "user/deposit_history",
      method: "post",
      reducerName: "transactiondata",
      payload: detail,
    };
    commonApiCall(Data, (response) => {
      if (response) {
        setTransaction(response.data[0]?.data);
      }
    });
  }, [page, verify_withdraw]);
  const handlechange = (value) => {
    setPage(value);
  };

  const sliceMethod = (item) => {
    if (item) {
      let sliceFormat = `${item?.slice(0, 8)}...${item?.slice(-6)}`
      return sliceFormat;
    } else return null;
  }

  return (
    <>
      <PageLoader load={loading} />
      <div className="transaction_section_outer_wrap minHght px-4">
        <h3 className="page_title_wrap">{t("Transaction")}</h3>

        <div className="bets_table_outer_wrap ">
          <div className="bets_table_inner_wrap table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="bet_id_col">{t("Date /Time")}{" "}<img src={down_arrow} alt="down_arrow" /></th>
                  <th>{t("Type")} <img src={down_arrow} alt="down_arrow" /></th>
                  <th>From Address</th>
                  <th>To Address</th>
                  <th>{t("Amount")}</th>
                  <th className="bet_id_col">{t("Transaction")}</th>
                  <th className="table_last_col">{t("Status")}</th>
                </tr>
              </thead>
              {transaction?.length > 0 ? (
                <tbody>
                  {transaction?.map((element, item) => {

                    let explorer = element?.network == "Bscscan" ? "https://testnet.bscscan.com/tx" : "https://goerli.etherscan.io//tx"

                    return (
                      <tr key={item} data-aos="zoom-in" data-aos-duration={item * 100}>
                        <td>
                          <h4>{Helper.formatDated(element.DATETIME)}</h4>
                        </td>
                        <td>
                          {" "}
                          {element?.TYPE == "Deposit" ?
                            <h4 className="bet_green_color">{element.TYPE}</h4>
                            :
                            <h4 className="bet_red_color">{element.TYPE}</h4>
                          }
                        </td>
                        <td>
                          <h4>{sliceMethod(element.from_address)}</h4>
                        </td>
                        <td>
                          <h4>{sliceMethod(element.to_address)}</h4>
                        </td>
                        <td className="text-left">
                          <h4>
                            {element?.amount}{" "}
                            {element.currency_name.toUpperCase()}
                          </h4>
                        </td>
                        <td>
                          <h4 className="bet_blue_color" data-tip data-for='txhash' type="button">
                            <ReactTooltip id='txhash' effect="solid" type="light">
                              <span>Click to view</span>
                            </ReactTooltip>
                            <a
                              href={`${explorer}/${element?.wallet_txid}`}
                              target="_blank"
                            >
                              {sliceMethod(element.wallet_txid)}
                            </a>
                          </h4>
                        </td>
                        <td className="table_last_col bet_matic_amount page_title_wrap">
                          <h4 className="bet_green_color">
                            <span>Completed</span>
                          </h4>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody className="page_title_wrap">
                  <tr>
                    <td colSpan={6}><Nodata /></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>


          <div className="table_pagination_outer_wrap">
            <div className="table_pagination_inner_wrap">
              <Pagination
                defaultCurrent={page}
                total={transactiondata?.data[0]?.pagination?.total}
                onChange={handlechange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  transactiondata: state.transactiondata,
  verify_withdraw: state.verify_withdraw,
  currencyIcon: state.currencyIcon,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
