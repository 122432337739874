import React, { useState, useEffect } from "react";
import { TabContent, TabPane } from "reactstrap";
import { connect } from "react-redux";
import Helper from "../Helper/Helper.js";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { PageLoader } from "./shared/loader";
import Aos from "aos";

function Bets({ commonApiCall, allbets, loading, currencyIcon }) {

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ once: true })
    document.title="HouseofPlinko | Game-history";
    const data1 = {
      page: page,
      pageSize: pageSize,
    };
 
    const Data1 = {
      url: "user/getallgamehistory",
      method: "post",
      reducerName: "allbets",
      payload: data1,
    };
    commonApiCall(Data1);
  }, [page]);

  const handlechange = (value) => {
    setPage(value);
  };

  const geticon = (cid) => {
    let getLogo = currencyIcon?.data?.filter(function (el) {
      if (el._id === cid) {
        return el;
      }
    });

    if (getLogo) return getLogo[0]?.image;
    else return null;
  };

  return (
    <><PageLoader load={loading}  />
      <div className="row pilnko_section_outer_wrap">
        <div className="col-xl-12 col-lg-12">
          <h3 className="page_title_wrap">Game History</h3>
          <div className="pilnko_tabs_outer_wrap">
          
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="bets_table_outer_wrap ">
                  <div className="bets_table_inner_wrap table-responsive">
                    {allbets ? (
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="bet_id_col">{t("Bet ID")}</th>
                            {/* <th>{t("Game")}</th> */}
                            <th>{t("Player")}</th>
                            <th>{t("Time")}</th>
                            <th className="bet_id_col">{t("Bet")}</th>
                            <th className="bet_payout_col">Profit</th>
                            <th className="table_last_col">Game Status</th>
                          </tr>
                        </thead>

                        {currencyIcon && allbets?.data[0]?.data?.length > 0 ? (
                          allbets?.data[0]?.data?.map((element, index) => (
                            <tbody key={index}>
                              <tr data-aos="zoom-in" data-aos-duration={index * 100}>
                                <td>
                                  <h4>{element.bet_id || '---'}</h4>
                                </td>
                                {/* <td>
                                  <h4>{element.game_name || '---'}</h4>
                                </td> */}
                                <td>
                                  <h4>{element.username || '---'}</h4>
                                </td>
                                <td>
                                  <h4>
                                    {Helper.formatDated(element.created_at) || '---'}
                                  </h4>
                                </td>
                    
                              
                                <td className="bet_matic_amount">
                                  <h4 className="bet_white_color">
                                    <h4>
                                      {element?.bet_amount || '---'}
                                    </h4>
                                  </h4>
                                  <img src={geticon(element.cid)} alt="image" />
                                </td>
                               
                                <td>
                                  <h4>{element.winning_amt || '---'}</h4>
                                </td>
                             
                                <td className="table_last_col bet_matic_amount">
                                  {element.profit_prediction ? (
                                    <h4 className="bet_green_color">
                                      <span>
                                        Won
                                      
                                       
                                      </span>
                                    </h4>
                                  ) : (
                                    <h4 className="bet_red_color">
                                      <span>
                                        Loss
                                       
                                      </span>
                                    </h4>
                                  )}
                                </td>
                              
                           
                                
                              </tr>

                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td>
                                <h4></h4>
                              </td>
                              <td>
                                <h4></h4>
                              </td>
                              <td>
                                <h4></h4>
                              </td>
                              <td>
                                <h4 className="bet_white_color">
                                  <span>{t("Records Not Found")}</span>
                                </h4>
                              </td>
                              <td className="bet_matic_amount">
                                <h4 className="bet_white_color">
                                  <span></span>
                                </h4>
                              </td>
                              <td>
                                <h4></h4>
                              </td>
                              <td className="table_last_col bet_matic_amount">
                                <h4 className="bet_green_color">
                                  <span></span>
                                </h4>
                              </td>
                            </tr>

                          </tbody>
                        )}
                      </table>

                      
                    ) : null}
                  </div>

                </div>
              </TabPane>
        
            </TabContent>

            <div className="table_pagination_outer_wrap">
              <div className="table_pagination_inner_wrap">
                <Pagination
                  defaultCurrent={page}
                  total={allbets?.data[0]?.pagination?.total}
                  onChange={handlechange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  mybets: state.mybets,
  allbets: state.allbets,
  currencyIcon: state.currencyIcon,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bets);
