import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"

// Importing translation files

import translationEN from "./English.json";
import translationHE from "./Hindi.json";
import translationGE from "./Germanese.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  hn: {
    translation: translationHE,
  },
  ge: {
    translation: translationGE,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem("lang"),
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });


