import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../shared/loader";

function Termscondition({ commonApiCall }) {

  const [showContent, setShowContent] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title="HouseofPlinko | Terms & conditions";

    const Data1 = {
      url: "getcms",
      method: "get",
      reducerName: "getAboutus",
    };
    commonApiCall(Data1, (response) => {
      if (response) {
        setShowContent(response.data[1]?.content);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <PageLoader load={loading} />
      <div className="minHght">
        <div className="cms_section_outer_wrap">
          <h3 className="page_title_wrap">
            {t("Terms & Conditions")}
          </h3>
          <div className="row">
            <div className="col-lg-12">
              <div className="cms_content_inner_wrap">
                <p dangerouslySetInnerHTML={{ __html: showContent }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(null, mapDispatchToProps)(Termscondition);
