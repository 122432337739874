import React, { useState, Suspense, useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRouter";
import "./style.css";
import "./custom.scss";
import { CustomUseContext } from "./context/ContextProvider";
import { ToastContainer } from "react-toastify";


import Home from './Pages/Home';
import Bets from './Pages/Bets';
import Gamehistory from './Pages/Gamehistory';
import Statistics from './Pages/Statistics';
import Transaction from './Pages/Transaction';
import Contactus from './Pages/Contactus';
import Profile from './Pages/Profile';
import About from './Pages/cms/About';
import Nopage from './Pages/cms/Nopage';
import Privacypolicy from './Pages/cms/Privacypolicy';
import Termscondition from './Pages/cms/Termscondition';
import Faq from './Pages/cms/Faq';

import { PageLoader } from "./Pages/shared/loader";
import hptele from "./assets/images/hptele.png";
import SiteIdle from './Components/siteIdle';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Wallet from "./Pages/Wallet";
import PlinkoGame from './Pages/Plinko';
import MobPlinko from "./Pages/MobPlinko";
import { AnimatePresence } from "framer-motion";


function App() {

  const location = useLocation();
  const { userToken, setUserToken, sitesettings } = CustomUseContext();

  useEffect(() => {
    setUserToken(localStorage.getItem("hop-access-key"));
  }, [userToken]);

  return (
    <div className="App">
      {location?.pathname !== "/Mobplinko" && <Header />}

      <SiteIdle />

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <AnimatePresence>
        <React.Fragment>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route exact path="/" element={<Home />} />

              <Route exact path="/bets" element={<PrivateRoute />}>
                <Route exact path="/bets" element={<Bets />} />
              </Route>
              <Route exact path="/CRev4SKjd53bs/:tab" element={<PrivateRoute />}>
                <Route exact path="/CRev4SKjd53bs/:tab" element={<Wallet />} />
              </Route>

              {/* Gamehistory */}

              <Route exact path="/Gamehistory" element={<PrivateRoute />} >
                <Route exact path="/Gamehistory" element={<Gamehistory />} />
              </Route>

              <Route exactpath="/CRX13KxYY58sY" element={<PrivateRoute />} >
                <Route exact path="/CRX13KxYY58sY" element={<Transaction />} />
              </Route>

              <Route exact path="/CR1wmDruLQkWc" element={<PrivateRoute />}>
                <Route path="/CR1wmDruLQkWc" element={<Statistics />} />
              </Route>
              <Route exact path="/CRFw2rPDaR2KQ" element={<PrivateRoute />}>
                <Route exact path="/CRFw2rPDaR2KQ" element={<Contactus />} />
              </Route>

              <Route exact path="/CRiFhNLxx07vg" element={<PrivateRoute />}>
                <Route exact path="/CRiFhNLxx07vg" element={<Profile />} />
              </Route>

              <Route path="/plinko" element={<PrivateRoute />} >
                <Route exact path="/plinko" element={<PlinkoGame />} />
              </Route>

              <Route exact path="/Mobplinko" element={<MobPlinko />} />

              {/* Content management */}
              <Route exact path="/about" element={<About />} />
              <Route exact path="/CRWiFeR2zzlqA" element={<Privacypolicy />} />
              <Route path="/CRSw7lHTO0PYY" element={<Termscondition />} />
              <Route path="CRo2PawQXl6Ek" element={<Faq />} />

              <Route path="*" element={<Nopage />} />
            </Routes>
          </Suspense>
        </React.Fragment>
      </AnimatePresence>

      {location?.pathname !== "/Mobplinko" &&
        <a className="floatIcn" href={sitesettings?.Login_url} target="_blank">
          <img src={hptele} className="img-fluid" />
        </a>}

      {location?.pathname !== "/Mobplinko" && <Footer />}
    </div>
  );
}

export default App;
