import React from "react";
import "../style.css";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TfaVerify = ({
  commonApiCall,
  loading,
}) => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onChange", });
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const onSubmit = (data) => {
    const verify = {
      _id: localStorage.getItem("userID"),
      tfa_code: data.tfa_code,
      type : "login"
    };
    const Data = {
      url: "user/verify_tfa",
      method: "post",
      reducerName: "tfaVerifyRes",
      payload: verify,
    };
    commonApiCall(Data);
  };
  return (
    <form
      className="sigin_form_wrap rest_password_wrap"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete={"off"}
    >
      <h4>{t("Two Factor Authentication")}</h4>
      <div className="form-group">
        <label htmlFor="code">2FA Code</label>
        <input
          type="number"
          placeholder="Enter Your 2FA Code"
          id="code"
          name="tfa_code"
          onKeyDown={handleKeyDown}
          {...register("tfa_code", {
            required: t("Tfareq"),
            pattern: {
              value: /^(\+\d{1,3}[- ]?)?\d{6}$/,
              message: t("Tfapatt"),
            },
          })}
        />
        {errors.tfa_code && (
          <p className="text-danger">{errors.tfa_code.message}</p>
        )}
      </div>

      <div className="signin_submit d-flex justify-content-center">
        <button type="submit" className="signin_btn_wrap" disabled={loading}>
          {loading ? "loading..." : t("submit")}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  tfaVerifyRes: state.tfaVerifyRes,
  signInResponse: state.signInResponse,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TfaVerify);
